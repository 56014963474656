$(document).ready(function(){
	// conteneur des slides
	var $slider = $('.mcDiaporama-list');

	// if($slider.find('.mcDiaporama-item').length > 1){
	// 	$slider.csSlider('diaporama');
	// }
	
	if($slider.find('.mcDiaporama-item').length > 1){
		var $sliderHome = $slider.bxSlider({
			mode: 'fade',
			speed: 600,
			pause: 3600,
			auto: true,
			controls: false,
			pager: false,
			autoHover: true,
		});
	}
});